import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Box, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";

import { format } from "date-fns";

import { IntervaloDataContext } from "../../Context/IntevervaloDataContext";
import checarData from "../../../../utils/checarData";
import converteData from "../../../../utils/converteData";
import formatDateBD from "../../../../utils/formatarDataBD";

import Tabela from "../../../../componentsv2/Tabela";
import Waiter from "../../../../components/Backdrop";

import { api } from "../../../../services/Api";
import { GridCellEditStopReasons } from "@mui/x-data-grid";
import styled from "@emotion/styled";
import Paper from "../../../../componentsv2/Paper";
import { RecebimentoContext } from "../../Recebimento/Context/RecebimentoContext";

function TabelaFaturamento(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { dataInicio, dataFim, competencia } = useContext(IntervaloDataContext);
  const { setAbaAtual } = useContext(RecebimentoContext);

  const {
    setResultado,
    dadosTabelaFaturamento,
    setDadosTabelaFaturamento,
    atualizaTabelaFaturamento,
    setId_lote,
    setCodigo,
    setPlano,
    setAbertura,
    setValor,
    setProtocolo,
    setMes_competencia,
    setAno_competencia,
    setData_previsao,
    setOpcoes,
    setProtocoloAtual,
    setIdProtocolo,
    setAtualizaTabelaFaturamento,
    setCodigoLote,
    setQuantidade,
    setDataEnvioLote,
  } = props;

  const [aguarda, setAguarda] = useState(false);

  useEffect(() => {
    (async () => {
      setAguarda(true);
      try {
        if (
          checarData(formatDateBD(dataInicio)) &&
          checarData(formatDateBD(dataFim))
        ) {
          setAbaAtual("faturamento");
          const data_inicial = `${formatDateBD(dataInicio)} 00:00:00`;
          const data_final = `${formatDateBD(dataFim)} 23:59:59`;

          const { data: results } = await api.get(
            `/v2/faturamento/loteFaturamento`,
            {
              params: { data_inicial, data_final, competencia },
            }
          );

          setResultado(results);
          setDadosTabelaFaturamento(results);
        }
      } catch (e) {
        enqueueSnackbar(
          "Não foi possível encontrar pedidos desta data. Por favor, tente novamente.",
          { variant: "error" }
        );
      }
      setAguarda(false);
    })();
    // eslint-disable-next-line
  }, [
    enqueueSnackbar,
    atualizaTabelaFaturamento,
    dataInicio,
    dataFim,
    competencia,
  ]);

  const handleEditClick = (row) => {
    setIdProtocolo(row?.id_protocolo);
    setId_lote(row.id);
    setCodigoLote(row.lote);
    setCodigo(row.lote);
    setPlano(row.plano);
    setAbertura(row.mes_competencia + "/" + row.ano_competencia);
    setValor(row.valor);
    setProtocoloAtual(row.protocolo ? row.protocolo : "");
    setMes_competencia(row.mes_competencia);
    setAno_competencia(row.ano_competencia);
    setData_previsao(row.data_previsao ? new Date(row.data_previsao) : null);
    setOpcoes(true);
    setQuantidade(row.qtd_guias);
    setDataEnvioLote(
      row.data_envio_lote ? new Date(row.data_envio_lote) : null
    );
  };

  const colunas = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      width: 90,
      hide: true,
    },
    {
      field: "em_construcao",
      headerName: "Em Construção",
      headerAlign: "center",
      minWidth: 90,
      hide: true,
    },
    {
      field: "opcoes",
      headerName: "Opções",
      headerAlign: "center",
      align: "center",
      width: 130,
      type: "number",
      editable: false,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: ({ row }) => (
        <div style={{ cursor: "pointer" }}>
          <IconButton
            onClick={() => {
              handleEditClick(row);
            }}
          >
            <i className="fa fa-cog" style={{ color: "#003399" }} />
          </IconButton>
        </div>
      ),
    },
    {
      field: "lote",
      headerName: "Lote",
      headerAlign: "center",
      type: "number",
      width: 120,
      editable: false,
    },
    {
      field: "qtd_guias",
      headerName: "N° de Guias",
      headerAlign: "center",
      type: "number",
      width: 150,
      editable: false,
    },
    {
      field: "plano",
      headerName: "Plano",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
    {
      field: "valor",
      headerName: "Valor",
      type: "number",
      headerAlign: "center",
      width: 150,
      valueFormatter: ({ value }) =>
        value
          ? `${value.toLocaleString("pt-BR", { currency: "BRL" })}`
          : "0,00",
    },
    {
      field: "data_envio_lote",
      headerName: "Data Envio",
      headerAlign: "center",
      width: 160,
      editable: false,
      valueFormatter: ({ value }) =>
        value ? `${format(new Date(value), "dd/MM/yyyy HH:mm")}` : null,
    },
    {
      field: "protocolo",
      headerName: "Protocolo",
      headerAlign: "center",
      flex: 1,
      minWidth: 130,
      editable: true,
    },
    {
      field: "competencia",
      headerName: "Competência",
      headerAlign: "center",
      width: 160,
      editable: true,
      valueGetter: (params) => {
        const value = `${
          params.row.mes_competencia ? params.row.mes_competencia : ""
        }/${params.row.ano_competencia ? params.row.ano_competencia : ""}`;
        return value;
      },
    },
    {
      field: "data_previsao",
      headerName: "Data Previsão",
      headerAlign: "center",
      width: 165,
      editable: false,
      valueFormatter: ({ value }) =>
        value ? `${format(new Date(value), "dd/MM/yyyy")}` : null,
    },
  ];

  const processRowUpdate = async (newRow) => {
    try {
      let anoCompetencia = "";
      let mesCompetencia = "";

      if (newRow.competencia) {
        anoCompetencia = newRow.competencia.split("/")[1];
        mesCompetencia = newRow.competencia.split("/")[0];

        if (anoCompetencia?.length !== 4 || mesCompetencia?.length !== 2) {
          enqueueSnackbar("Formato do campo competência inválido.", {
            variant: "warning",
          });
          return;
        }
      } else {
        anoCompetencia = newRow.ano_competencia;
        mesCompetencia = newRow.mes_competencia;
      }

      await api.put(
        `/v2/faturamento/alteraProtocoloCompetencia/${newRow.id_protocolo}`,
        {
          protocolo: newRow.protocolo,
          mes_competencia: mesCompetencia,
          ano_competencia: anoCompetencia,
        }
      );

      setAtualizaTabelaFaturamento((prevState) => !prevState);

      if (newRow.competencia) {
        enqueueSnackbar("Competência atualizada.", { variant: "success" });
      } else {
        enqueueSnackbar("Protocolo atualizado.", { variant: "success" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const StyledPaper = styled(Paper)(({ theme }) => ({
    "& .alerta": {
      backgroundColor: "#F5A9A9",
      fontWeight: "bold",
    },
    "& .alerta2": {
      backgroundColor: "#A9F5BC",
      fontWeight: "bold",
      border: "5px solid #F5A9A9",
    },
    "& .atual": {
      backgroundColor: "#A9D0F5",
      fontWeight: "bold",
    },
    "& .melhor": {
      backgroundColor: "#A9F5BC",
      fontWeight: "bold",
    },
    "& .menor": {
      backgroundColor: "#F3E2A9",
      fontWeight: "bold",
    },
  }));

  return (
    <>
      <StyledPaper style={{ backgroundColor: "White", width: "100%" }}>
        <Tabela
          disableColumnMenu
          autoHeight
          columns={colunas}
          rows={dadosTabelaFaturamento}
          pageSize={100}
          rowsPerPageOptions={[50, 100]}
          processRowUpdate={processRowUpdate}
          sx={{
            "& .MuiDataGrid-row": {
              "&.em-construcao": {
                backgroundColor: "#ffe082", // Apply orange background for rows with em_construcao = true
              },
            },
          }}
          getRowClassName={(params) => {
            return params.row.em_construcao ? "em-construcao" : "";
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                em_construcao: false,
              },
            },
          }}
        />
        <Waiter espera={aguarda} />
      </StyledPaper>
    </>
  );
}

export default memo(TabelaFaturamento);
