import React from "react";
import { Grid, Paper } from "@mui/material";
import EqualizerIcon from "@mui/icons-material/Equalizer";

import Tabela from "../../../../componentsv2/Tabela";

export default function Table() {
  const rows = [
    {
      id: 1,
      nome: "BI Notificações RNDS",
      link: "https://app.powerbi.com/links/pvifUEIQV_?ctid=a5da2cd0-815f-4af4-ac60-6c6b593b7957&pbi_source=linkShare",
      visualizarBI: "Visualizar BI",
    },
    {
      id: 2,
      nome: "BI Almoxarifado",
      link: "https://app.powerbi.com/links/TDq517wmXm?ctid=a5da2cd0-815f-4af4-ac60-6c6b593b7957&pbi_source=linkShare",
      visualizarBI: "Visualizar BI",
    },
    {
      id: 3,
      nome: "BI Controle de Exames",
      link: "https://app.powerbi.com/links/PCJST_6UKB?ctid=a5da2cd0-815f-4af4-ac60-6c6b593b7957&pbi_source=linkShare",
      visualizarBI: "Visualizar BI",
    },
    {
      id: 4,
      nome: "BI Ferramentas",
      link: "https://app.powerbi.com/links/eISkwdbol1?ctid=a5da2cd0-815f-4af4-ac60-6c6b593b7957&pbi_source=linkShare",
      visualizarBI: "Visualizar BI",
    },
    {
      id: 5,
      nome: "BI Faturamento",
      link: "https://app.powerbi.com/links/eyIxO8JDTE?ctid=a5da2cd0-815f-4af4-ac60-6c6b593b7957&pbi_source=linkShare",
      visualizarBI: "Visualizar BI",
    },
    {
      id: 6,
      nome: "BI Médicos",
      link: "https://app.powerbi.com/links/M1-LA3lKxM?ctid=a5da2cd0-815f-4af4-ac60-6c6b593b7957&pbi_source=linkShare",
      visualizarBI: "Visualizar BI",
    },
    {
      id: 7,
      nome: "BI Tabela de Preços",
      link: "https://app.powerbi.com/links/4V62JZJpgz?ctid=a5da2cd0-815f-4af4-ac60-6c6b593b7957&pbi_source=linkShare",
      visualizarBI: "Visualizar BI",
    },
    {
      id: 8,
      nome: "BI COVID",
      link: "https://app.powerbi.com/links/3G-aSLJ8Aj?ctid=a5da2cd0-815f-4af4-ac60-6c6b593b7957&pbi_source=linkShare",
      visualizarBI: "Visualizar BI",
    },
    {
      id: 9,
      nome: "BI Mensagens",
      link: "https://app.powerbi.com/links/PCJST_6UKB?ctid=a5da2cd0-815f-4af4-ac60-6c6b593b7957&pbi_source=linkShare",
      visualizarBI: "Visualizar BI",
    },
    {
      id: 10,
      nome: "BI Produtividade",
      link: "https://app.powerbi.com/links/OJtLrZqw3c?ctid=a5da2cd0-815f-4af4-ac60-6c6b593b7957&pbi_source=linkShare",
      visualizarBI: "Visualizar BI",
    },
    {
      id: 11,
      nome: "BI Preço Exames",
      link: "https://app.powerbi.com/links/2WzVb-ABjo?ctid=a5da2cd0-815f-4af4-ac60-6c6b593b7957&pbi_source=linkShare",
      visualizarBI: "Visualizar BI",
    },
    {
      id: 12,
      nome: "BI Marketing",
      link: "https://app.powerbi.com/links/qriM3TG11Y?ctid=a5da2cd0-815f-4af4-ac60-6c6b593b7957&pbi_source=linkShare",
      visualizarBI: "Visualizar BI",
    },
  ];
  const columns = [
    {
      field: "nome",
      headerName: "Nome",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },

    {
      field: "visualizarBI",
      headerName: "Visualizar BI",
      headerAlign: "center",
      align: "center",
      width: 220,
      type: "number",
      editable: false,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <a
          href={params.row.link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ cursor: "pointer" }}
        >
          <EqualizerIcon />
        </a>
      ),
    },
  ];
  return (
    
    <Grid alignItems="center"
    justifyContent="center"
    style={{ padding: "1rem 1rem" }} spacing={2} container>
      <Grid item xs={12} sm={12}>
        <Paper elevation={2}>
          <div style={{ height: 600, width: "100%" }}>
            <Tabela ocultarToolbar columns={columns} rows={rows} />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}
