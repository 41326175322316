import React, { useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  Button,
  List,
  ListItemText,
  Paper,
  ListItemIcon,
  TextField,
  Dialog,
  ListItemButton,
  Autocomplete,
} from "@mui/material";
import {
  Delete,
  PostAdd,
  NoteAltOutlined,
  SyncOutlined,
} from "@mui/icons-material";
import { format } from "date-fns";

import GerenciarGuias from "./GerenciarGuias";
import Totalizadores from "./Totalizadores";
import TabelaFaturamento from "./TabelaFaturamento";

import CorpoModal from "../Componentes/CorpoModal";
import Formulario from "../Componentes/Formulario";
import MascaraDeData from "../Componentes/MascaraDeData";

import Waiter from "../../../components/Backdrop";

import converteDataUS from "../../../utils/converteDataUS";

import DataPickerAtualizado from "../../../components/DataPickerAtualizado";

import {
  RecebimentoContext,
  RecebimentoContextProvider,
} from "../Recebimento/Context/RecebimentoContext";
import { ModalRecebimentoContextProvider } from "../Recebimento/Context/ModalRecebimentoContext";

import { api } from "../../../services/Api";
import { IntervaloDataContext } from "../Context/IntevervaloDataContext";
import { Context as PermissoesContext } from "../../../Context/PermissoesContext";
import { Modal as ModalConfirmacao } from "../../../componentsv2/Modal";

export default function FaturamentoTabela() {
  const { enqueueSnackbar } = useSnackbar();

  const [tela, setTela] = useState(0);

  const [opcoes, setOpcoes] = useState(false);
  const [aguarda, setAguarda] = useState(false);
  const [modalProtocolo, setModalProtocolo] = useState(false);
  const [atualizaTabelaFaturamento, setAtualizaTabelaFaturamento] =
    useState(false);

  const [mes_competencia, setMes_competencia] = useState(null);
  const [ano_competencia, setAno_competencia] = useState(null);
  const [data_previsao, setData_previsao] = useState(null);
  const [protocoloAtual, setProtocoloAtual] = useState("");

  const [dadosTabelaFaturamento, setDadosTabelaFaturamento] = useState([]);
  const [totalSoftlab, setTotalSoftlab] = useState([]);
  const [totalSoftlabFiltrado, setTotalSoftlabFiltrado] = useState([]);
  const [total, setTotal] = useState([]);
  const [id_lote, setId_lote] = useState("");
  const [codigo, setCodigo] = useState("");
  const [abertura, setAbertura] = useState(null);
  const [valor, setValor] = useState("");
  const [plano, setPlano] = useState("");
  const [codigoLote, setCodigoLote] = useState("");
  const [quantidade, setQuantidade] = useState("");
  const [dataEnvioLote, setDataEnvioLote] = useState("");

  const [idProtocolo, setIdProtocolo] = useState("");
  const [listaConvenio, setListaConvenio] = useState([]);

  const [loadingGeradoSoftlab, setLoadingGeradoSoftlab] = useState(false);
  const { checarPermissao } = useContext(PermissoesContext);
  const [openModalConfirmarRemocaoLote, setOpenModalConfirmarRemocaoLote] =
    useState(false);

  const [modalEdita, setModalEdita] = useState(false);

  const {
    aba,
    resultado,
    setResultado,
    protocolo,
    setProtocolo,
    plano: listaPlanos,
    lote,
    setLote,
    atualizaTabela,
    atualizaGeradoSoftlab,
    listaConvenios,
  } = useContext(RecebimentoContext);

  const { dataInicio, dataFim, competencia } = useContext(IntervaloDataContext);

  const fechaOpcoes = () => {
    setOpcoes(false);
    setProtocoloAtual("");
    setData_previsao(null);
  };

  const fechaModalProtocolo = () => {
    setModalProtocolo(false);
    setProtocoloAtual("");
    setData_previsao(null);
  };

  const getFaturamentoSoftlab = async () => {
    try {
      setLoadingGeradoSoftlab(true);
      const { data: results } = await api.get(
        `/api/BuscaPorPedidos/geradoSoftlab`,
        {
          params: {
            data_inicial: dataInicio,
            data_final: dataFim,
            competencia,
          },
        }
      );
      setTotalSoftlab(results);
    } catch (error) {
      enqueueSnackbar("Erro ao buscar faturamento do softlab.", {
        variant: "error",
      });
      console.log(error);
    } finally {
      setLoadingGeradoSoftlab(false);
    }
  };

  useEffect(() => {
    const filtroConvenio = listaPlanos?.map((item) => item.convenio);
    let result = [];

    if (filtroConvenio?.includes("Todos")) {
      result = totalSoftlab;
    } else {
      result = totalSoftlab?.filter((item) => {
        const codGuia = item.cod_guia.trim().toUpperCase();

        // Se "UNIMED" foi selecionado, aceita qualquer convênio que contenha "UNIMED"
        const isUnimed =
          filtroConvenio.includes("UNIMED") && codGuia.includes("UNIMED");

        // Mantém os convênios que foram escolhidos explicitamente
        const isOtherConvenio = filtroConvenio.some((conv) => conv === codGuia);

        return isUnimed || isOtherConvenio;
      });
    }

    const soma = result.reduce((soma, atual) => soma + atual.faturamento, 0);
    setTotalSoftlabFiltrado(soma);
  }, [listaPlanos, totalSoftlab]);

  useEffect(() => {
    getFaturamentoSoftlab();
  }, [atualizaGeradoSoftlab]);

  useEffect(() => {
    (async () => {
      const dataHoje = format(new Date(), "yyyy-MM-dd");

      const somaTotal = dadosTabelaFaturamento.reduce(
        (soma, atual) => soma + atual.valor,
        0
      );
      const somaTotalEnviado = dadosTabelaFaturamento.reduce((soma, atual) => {
        if (atual.protocolo) {
          return soma + atual.valor;
        }
        return soma;
      }, 0);
      const somaTotalRecebido = dadosTabelaFaturamento.reduce((soma, atual) => {
        if (atual.data_previsao && atual.data_previsao <= dataHoje) {
          return soma + atual.valor;
        }
        return soma;
      }, 0);
      const somaTotalAguardando = dadosTabelaFaturamento.reduce(
        (soma, atual) => {
          if (atual.data_previsao && atual.data_previsao > dataHoje) {
            return soma + atual.valor;
          }
          return soma;
        },
        0
      );
      setTotal({
        somaTotal,
        somaTotalEnviado,
        somaTotalRecebido,
        somaTotalAguardando,
      });
    })();
  }, [dadosTabelaFaturamento]);

  useEffect(() => {
    if (abertura) {
      if (abertura.includes("/") && abertura.split("/").length === 2) {
        const mes = abertura.split("/")[0];
        const ano = abertura.split("/")[1];
        setMes_competencia(mes);
        setAno_competencia(ano);
      }
    }
  }, [abertura]);

  async function adicionaProtocolo() {
    if (
      !mes_competencia ||
      !ano_competencia ||
      mes_competencia.length !== 2 ||
      ano_competencia.length !== 4
    ) {
      enqueueSnackbar("Preencha o campo competência.", { variant: "warning" });
      return;
    }
    setAguarda(true);
    const previsao = data_previsao ? converteDataUS(data_previsao) : null;

    if (!protocoloAtual) {
      enqueueSnackbar("Protocolo vazio.", { variant: "warning" });
    } else if (!data_previsao) {
      enqueueSnackbar("Preencha uma data de previsão.", { variant: "warning" });
    } else {
      try {
        await api.put(`/v2/faturamento/adicionaProtocolo`, {
          protocolo: protocoloAtual,
          previsao,
          codigo,
          plano,
          mes_competencia,
          ano_competencia,
        });

        fechaModalProtocolo();
        fechaOpcoes();

        setAtualizaTabelaFaturamento((prevState) => !prevState);
        enqueueSnackbar("Protocolo adicionado.", { variant: "success" });
      } catch (e) {
        enqueueSnackbar(
          "Não foi possível adicionar esse protocolo. Por favor, tente novamente.",
          { variant: "error" }
        );
      }
    }
    setAguarda(false);
  }

  async function atualizaLote() {
    try {
      await api.put(`/v2/faturamento/atualizaLote`, {
        id_protocolo: idProtocolo,
        id_lote,
        data_envio_lote: dataEnvioLote,
        data_previsao,
        codigo: codigoLote,
        plano,
        protocolo: protocoloAtual,
        valor,
        quantidade,
        mes_competencia,
        ano_competencia,
      });

      setModalEdita(false);
      setAtualizaTabelaFaturamento((prevState) => !prevState);
      enqueueSnackbar("Lote Atualizado.", { variant: "success" });
    } catch (e) {
      enqueueSnackbar(
        "Não foi possível atualizar esse lote. Por favor, tente novamente.",
        { variant: "error" }
      );
    }
    setAguarda(false);
  }

  async function apagaLote() {
    if (!checarPermissao("FAT010")) {
      enqueueSnackbar("Você não tem permissão para realizar esta ação", {
        variant: "error",
      });
      return;
    }
    setAguarda(true);
    try {
      await api.delete(`/v2/faturamento/deletaLote/${id_lote}`);
      await api.delete(`/v2/faturamento/deletaLoteProtocolo/${idProtocolo}`);

      setAtualizaTabelaFaturamento((prevState) => !prevState);
      fechaOpcoes();
      enqueueSnackbar("Lote apagado", { variant: "success" });
    } catch (error) {
      enqueueSnackbar(
        "Não foi possível apagar esse lote. Por favor, tente novamente.",
        {
          variant: "error",
        }
      );
    }
    setAguarda(false);
  }
  const getConvenios = async () => {
    try {
      const results = await api.get("/v2/faturamento/convenios");
      const listaConvenio = results.data;
      setListaConvenio(listaConvenio);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getConvenios();
  }, []);

  async function sincronizaLoteIndividual() {
    //setAguarda(true);

    try {
      await api.post(
        `/ativa/faturamento/sincronizaTabelaLoteIndividual/?id_lote=${id_lote}&lote=${codigoLote}&convenio=${plano}`
      );

      setAtualizaTabelaFaturamento((prevState) => !prevState);
      enqueueSnackbar("Lote atualizado com sucesso.", {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(
        "Não foi possível atualizar a tabela de faturamento. Por favor, tente novamente.",
        { variant: "error" }
      );
    }
    //setAguarda(false);
  }

  const opcoesModal = (
    <Paper
      style={{ display: "flex", margin: "1rem", justifyContent: "center" }}
    >
      <ModalConfirmacao
        confirmActionButtonColor="green"
        onClose={setOpenModalConfirmarRemocaoLote}
        open={openModalConfirmarRemocaoLote}
        onClick={apagaLote}
        modalText={"Tem certeza que deseja remover este lote?"}
      />
      <List component="nav" aria-label="main mailbox folders">
        <ListItemButton onClick={() => setModalProtocolo(true)}>
          <ListItemIcon>
            <PostAdd color="success" />
          </ListItemIcon>
          <ListItemText primary="Gerenciar Protocolo" />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            sincronizaLoteIndividual();
          }}
        >
          <ListItemIcon>
            <SyncOutlined color="success" />
          </ListItemIcon>
          <ListItemText primary="Sincronizar Lote" />
        </ListItemButton>
        <ListItemButton onClick={() => setModalEdita(true)}>
          <ListItemIcon>
            <NoteAltOutlined color="success" />
          </ListItemIcon>
          <ListItemText primary="Editar Lote" />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            setOpenModalConfirmarRemocaoLote(true);
          }}
        >
          <ListItemIcon>
            <Delete color="error" />
          </ListItemIcon>
          <ListItemText primary="Remover" />
        </ListItemButton>
      </List>
    </Paper>
  );

  const protocoloModal = (
    <Paper>
      <div style={{ padding: "1.5rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "5px",
          }}
        >
          <TextField
            id="protocolo"
            autoFocus
            style={{ marginRight: "1rem", minWidth: "6rem", width: "50%" }}
            label="Protocolo"
            defaultValue={protocoloAtual || ""}
            onBlur={(e) => setProtocoloAtual(e.target.value.toUpperCase())}
          />
          <TextField
            id="competencia"
            style={{ marginRight: "1rem", minWidth: "6rem", width: "40%" }}
            label="Competência"
            defaultValue={
              mes_competencia !== ""
                ? `${mes_competencia}/${ano_competencia}`
                : ""
            }
            onBlur={(e) => {
              const competencia = e.target.value;
              const [mes, ano] = competencia.split("/");
              setMes_competencia(mes);
              setAno_competencia(ano);
            }}
          />
          <TextField
            id="data_previsao"
            placeholder="dd/mm/aaaa"
            required
            style={{ marginRight: "1rem", minWidth: "6rem", width: "40%" }}
            label="Data de Previsão"
            onBlur={(e) => setData_previsao(e.target.value)}
            defaultValue={data_previsao || ""}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: MascaraDeData,
            }}
          />
        </div>
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}
        >
          <Button
            variant="contained"
            onClick={adicionaProtocolo}
            style={{
              width: "10rem",
              height: "2.2rem",
              backgroundColor: "green",
              color: "white",
            }}
          >
            salvar
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setModalEdita(false)}
            style={{ width: "10rem", height: "2.2rem", marginLeft: "1rem" }}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </Paper>
  );

  const editaModal = (
    <Paper style={{ padding: "1.5rem", width: "100%", maxWidth: "800px" }}>
      <div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "1rem",
            marginTop: "5px",
          }}
        >
          <TextField
            id="protocolo"
            autoFocus
            style={{ flex: "1 1 45%", minWidth: "200px" }}
            label="Protocolo"
            value={protocoloAtual}
            onChange={(e) => setProtocoloAtual(e.target.value)}
          />
          <TextField
            id="codigoLote"
            style={{ flex: "1 1 45%", minWidth: "200px" }}
            label="Lote"
            value={codigoLote}
            onChange={(e) => setCodigoLote(e.target.value)}
          />
          <Autocomplete
            style={{ flex: "1 1 45%", minWidth: "200px" }}
            options={listaConvenio?.map((item) => item.convenio)}
            required
            value={plano}
            onChange={(event, value) => {
              setPlano(value);
            }}
            renderInput={(params) => <TextField {...params} label="Convênio" />}
          />
          {/* <TextField
            id="plano"
            style={{ flex: "1 1 45%", minWidth: "200px" }}
            label="Plano"
            value={plano}
            onChange={(e) => setPlano(e.target.value)}
          /> */}
          <TextField
            id="competencia"
            style={{ flex: "1 1 45%", minWidth: "200px" }}
            label="Competência"
            value={
              mes_competencia !== ""
                ? `${mes_competencia}/${ano_competencia}`
                : ""
            }
            onChange={(e) => {
              const competencia = e.target.value;
              const [mes, ano] = competencia.split("/");
              setMes_competencia(mes);
              setAno_competencia(ano);
            }}
          />
          <DataPickerAtualizado
            data={data_previsao}
            labelName="Data Previsão"
            setData={setData_previsao}
          />
          <TextField
            id="valor"
            style={{ flex: "1 1 45%", minWidth: "200px" }}
            label="Valor"
            value={valor}
            onChange={(e) => setValor(e.target.value)}
          />
          <TextField
            id="quantidade"
            style={{ flex: "1 1 45%", minWidth: "200px" }}
            label="Quantidade"
            value={quantidade}
            onChange={(e) => setQuantidade(e.target.value)}
          />
          <DataPickerAtualizado
            data={dataEnvioLote}
            labelName="Data Envio do Lote"
            setData={setDataEnvioLote}
          />
        </div>
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}
        >
          <Button
            variant="contained"
            onClick={atualizaLote}
            style={{
              width: "10rem",
              height: "2.2rem",
              backgroundColor: "green",
              color: "white",
            }}
          >
            Atualizar
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setModalEdita(false)}
            style={{ width: "10rem", height: "2.2rem", marginLeft: "1rem" }}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </Paper>
  );

  const faturamento = (
    <div>
      <Formulario
        faturamento
        setDadosTabelaFaturamento={setDadosTabelaFaturamento}
        tabelaFaturamento={resultado}
        setAtualizaTabelaFaturamento={setAtualizaTabelaFaturamento}
      />

      <Totalizadores
        total={total}
        totalSoftlabFiltrado={totalSoftlabFiltrado}
        loadingGeradoSoftlab={loadingGeradoSoftlab}
        setAtualizaTabelaFaturamento={setAtualizaTabelaFaturamento}
      />

      <TabelaFaturamento
        setResultado={setResultado}
        dadosTabelaFaturamento={dadosTabelaFaturamento}
        setDadosTabelaFaturamento={setDadosTabelaFaturamento}
        atualizaTabelaFaturamento={atualizaTabelaFaturamento}
        setId_lote={setId_lote}
        setCodigoLote={setCodigoLote}
        setIdProtocolo={setIdProtocolo}
        setCodigo={setCodigo}
        setPlano={setPlano}
        setAbertura={setAbertura}
        setValor={setValor}
        setProtocolo={setProtocolo}
        setMes_competencia={setMes_competencia}
        setAno_competencia={setAno_competencia}
        setData_previsao={setData_previsao}
        setOpcoes={setOpcoes}
        setProtocoloAtual={setProtocoloAtual}
        setAtualizaTabelaFaturamento={setAtualizaTabelaFaturamento}
        setQuantidade={setQuantidade}
        setDataEnvioLote={setDataEnvioLote}
      />

      <Dialog
        maxWidth="sm"
        open={opcoes}
        onClose={fechaOpcoes}
        closeAfterTransition
      >
        <CorpoModal titulo="Opções" fechaModal={fechaOpcoes}>
          {opcoesModal}
        </CorpoModal>
      </Dialog>
      <Dialog
        maxWidth="sm"
        open={modalProtocolo}
        onClose={fechaModalProtocolo}
        closeAfterTransition
      >
        <CorpoModal
          titulo="Gerenciar Protocolo"
          fechaModal={() => setModalProtocolo(false)}
        >
          {protocoloModal}
        </CorpoModal>
      </Dialog>
      <Dialog
        fullWidth // Take up the full width available
        open={modalEdita}
        onClose={() => setModalEdita(false)}
        closeAfterTransition
        scroll="paper" // Enable scrolling if content overflows
      >
        <CorpoModal
          titulo="Editar Lote"
          fechaModal={() => setModalEdita(false)}
        >
          {editaModal}
        </CorpoModal>
      </Dialog>
      <Waiter espera={aguarda} />
    </div>
  );

  return (
    <>
      {tela === 0 && faturamento}
      {tela === 1 && (
        <GerenciarGuias
          id_lote={id_lote}
          plano={plano}
          abertura={abertura}
          valor={valor}
          protocolo={protocolo}
          setTela={setTela}
          fechaOpcoes={fechaOpcoes}
        />
      )}
    </>
  );
}
