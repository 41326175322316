import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  IconButton,
  Paper,
  FormControl,
  Modal,
  Button,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import Tabela from "../../../componentsv2/Tabela";
import { useSnackbar } from "notistack";
import GridItem from "../../../componentsv2/GridItem";
import GridContainer from "../../../componentsv2/GridContainer";
import DataPickerAtualizado from "../../../components/DataPickerAtualizado";

import { api } from "../../../services/Api";

import { formatarDataParaBR } from "../../../utils/formatarUTCString";
import Waiter from "../../../components/Backdrop";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

export default function NaoFaturados() {
  const { enqueueSnackbar } = useSnackbar();

  const [dadosTabelaNaoFaturados, setDadosTabelaNaoFaturados] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [dataInicio, setDataInicio] = useState(() => {
    const valueDateI = new Date();
    valueDateI.setDate(valueDateI.getDate() - 10);
    return valueDateI;
  });
  const [dataFim, setDataFim] = useState(new Date());
  const [convenio, setConvenio] = useState([]);
  const [postoPedido, setPostoPedido] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [loading, setLoading] = useState(false);

  const colunas = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      flex: 1,
      hide: true,
    },
    {
      field: "datahora_atendimento",
      headerName: "Data Atendimento",
      headerAlign: "center",
      flex: 1,
      editable: false,
      valueFormatter: ({ value }) =>
        value ? formatarDataParaBR(new Date(value)) : null,
    },
    {
      field: "posto_pedido_codigo_pedido",
      headerName: "Pedido",
      headerAlign: "center",
      flex: 1,
      editable: false,
      valueGetter: (params) =>
        `${params.row.codigo_pedido}-${params.row.posto_pedido}`,
    },
    {
      field: "convenio",
      headerName: "Convenio",
      headerAlign: "center",
      type: "text",
      flex: 1,
      editable: false,
    },
    {
      field: "valor",
      headerName: "Valor",
      type: "number",
      headerAlign: "center",
      flex: 1,
      valueFormatter: ({ value }) =>
        value
          ? `${value.toLocaleString("pt-BR", { currency: "BRL" })}`
          : "0,00",
    },
    {
      field: "opcao",
      headerName: "Detalhes",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <IconButton
          variant="contained"
          color="primary"
          onClick={() => handleOpenModal(params.row)}
        >
          <i className="fa fa-cog" style={{ color: "#003399" }} />
        </IconButton>
      ),
    },
  ];

  const sincronizaNaoFaturados = useCallback(async () => {
    try {
      setLoading(true);
      const { data: results } = await api.get(`/api/Softlab/naoLoteados/`, {
        params: {
          data_inicial: dataInicio,
          data_final: dataFim,
        },
      });
      setDadosTabelaNaoFaturados(results);
      setFilteredData(results);
    } catch (error) {
      enqueueSnackbar("Erro ao buscar não loteados.", {
        variant: "error",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [dataInicio, dataFim, enqueueSnackbar]);

  useEffect(() => {
    sincronizaNaoFaturados();
  }, [sincronizaNaoFaturados]);

  const handleConvenioChange = (event, value) => {
    setConvenio(value);
    if (value.length === 0) {
      setFilteredData(dadosTabelaNaoFaturados);
    } else {
      const filtered = dadosTabelaNaoFaturados.filter((item) =>
        value.includes(item.convenio)
      );
      setFilteredData(filtered);
    }
  };

  const handlePostoPedidoChange = (event, value) => {
    setPostoPedido(value);
    if (value.length === 0) {
      setFilteredData(dadosTabelaNaoFaturados);
    } else {
      const filtered = dadosTabelaNaoFaturados.filter((item) =>
        value.includes(item.posto_pedido)
      );
      setFilteredData(filtered);
    }
  };

  const uniqueConvenios = [
    ...new Set(dadosTabelaNaoFaturados.map((item) => item.convenio)),
  ];

  const uniquePostoPedidos = [
    ...new Set(dadosTabelaNaoFaturados.map((item) => item.posto_pedido)),
  ];

  const handleOpenModal = (row) => {
    setSelectedRow(row);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRow(null);
  };

  return (
    <Paper id="container-formulario-lotes-faturamento">
      <GridContainer
        spacing={1}
        justifyContent="space-evenly"
        alignItems="center"
      >
        <GridItem xs={8}>
          <GridContainer
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={1}
            sx={{
              border: "0.1rem solid #CDCDCD",
              borderRadius: "0.2rem",
              padding: "1rem",
            }}
          >
            <GridItem xs={3}>
              <DataPickerAtualizado
                data={dataInicio}
                labelName="Data inicial"
                setData={setDataInicio}
              />
            </GridItem>
            <GridItem xs={3}>
              <DataPickerAtualizado
                data={dataFim}
                labelName="Data final"
                setData={setDataFim}
              />
            </GridItem>
            <GridItem xs={3}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  options={uniqueConvenios}
                  value={convenio}
                  onChange={handleConvenioChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Convenio" />
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={3}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  options={uniquePostoPedidos}
                  value={postoPedido}
                  onChange={handlePostoPedidoChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Posto Pedido" />
                  )}
                />
              </FormControl>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <>
        <Tabela
          disableColumnMenu
          autoHeight
          columns={colunas}
          rows={filteredData}
          pageSize={100}
          rowsPerPageOptions={[50, 100]}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
            },
            "& .MuiDataGrid-cell": {
              justifyContent: "center",
            },
          }}
        />
        <Waiter espera={loading} />
      </>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            color="text.primary"
          >
            Detalhes do Pedido
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: 2,
              color: "text.primary",
              "& p": { color: "text.primary" },
            }}
          >
            {selectedRow && (
              <>
                <p>
                  <strong>Posto:</strong> {selectedRow.posto_pedido}
                </p>
                <p>
                  <strong>Pedido:</strong> {selectedRow.codigo_pedido}
                </p>
                <p>
                  <strong>Usuario do Pedido:</strong> {selectedRow.usu_pedido}
                </p>
                <p>
                  <strong>Convênio:</strong> {selectedRow.convenio}
                </p>
                <p>
                  <strong>Valor:</strong>{" "}
                  {selectedRow.valor.toLocaleString("pt-BR", {
                    currency: "BRL",
                  })}
                </p>
                <p>
                  <strong>Data Atendimento:</strong>{" "}
                  {formatarDataParaBR(
                    new Date(selectedRow.datahora_atendimento)
                  )}
                </p>
              </>
            )}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseModal}
            sx={{ mt: 2 }}
          >
            Fechar
          </Button>
        </Box>
      </Modal>
    </Paper>
  );
}
