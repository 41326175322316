import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../../../components/Header";
import Tabela from "../../../componentsv2/Tabela";
import { Context as PermissoesContext } from "../../../Context/PermissoesContext";
import useUser from "../../../hooks/useUser";
import { api } from "../../../services/Api";
import converteData from "../../../utils/converteData";
import SalvaLog from "../../../utils/SalvaLog";
import SelectLocal from "../components/SelectLocal";
import AutocompleteEstoques from "../../Almoxarifado/components/AutocompleteEstoque";
import useDadosContextEstoque from "../hooks/useDadosContextEstoque";
import { Modal as ModalConfirmacao } from "../../../componentsv2/Modal";
import SelectLocalUnidades from "./components/SelectLocalUnidades";

export default function CadastraProdutoHome() {
  const history = useHistory();
  const { checarPermissao } = useContext(PermissoesContext);
  const location = useLocation();
  const usuario = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const edicaoProduto = location.state;

  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [data, setData] = useState(null);
  const [categorias, setCategorias] = useState([]);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState("");
  const [nomeProduto, setNomeProduto] = useState("");
  const [qtdPacote, setQtdPacote] = useState(1);
  const [codBarras, setCodBarras] = useState("");
  const [unidadeSelecionada, setUnidadeSelecionada] = useState("UND");
  const [tabelaDados, setTabelaDados] = useState([]);
  const [loteSelecionado, setLoteSelecionado] = useState("");
  const [formatYMD, setFormatYMD] = useState("");
  const [local, setLocal] = useState([]);
  const [localSelecionado, setLocalSelecionado] = useState("");
  const [dadosEdicao, setDadosEdicao] = useState([]);

  const [idLote, setIdLote] = useState(null);
  const [update, setUpdate] = useState(false);

  const [ativaTabela, setAtivaTabela] = useState(false);
  const [ativaLote, setAtivaLote] = useState(false);
  const [ativaBotaoAdicionar, setAtivaBotaoAdicionar] = useState(false);
  const [ativaBotaoEditarLote, setAtivaBotaoEditarLote] = useState(false);

  const [estoqueAtual, setEstoqueAtual] = useState({ nome: "", id: "" });

  const [quantidadeAtual, setQuantidadeAtual] = useState(0);
  const [idProdutoAtual, setIdProdutoAtual] = useState("");
  const [valorProduto, setValorProduto] = useState(0);
  const { semAcessoUnidade } = useDadosContextEstoque();

  const [openModalRemocao, setOpenModalRemocao] = useState(false);

  const [produtoAtual, setProdutoAtual] = useState({});
  const [loadingSubmitLote, setLoadingSubmitLote] = useState(false);

  async function excluirLote(params) {
    try {
      await api.put(`/v2/almoxarifado/lote/excluir/${params.row.id}`);
      SalvaLog("Lote excluído", "Almoxarifado", params.row.id);
      enqueueSnackbar("Produto excluído com sucesso", {
        variant: "success",
      });

      await api.post("/v2/almoxarifado/movimentcaoProdutoEstoque/saida", {
        id_produto_estoque: params.row.id,
        data: new Date(),
        tipo: "Saída",
        origem_destino: params.row.estoque.unidade.id,
        quantidade: params.row.quantidade,
        usuario,
        remocaoLote: true,
      });

      setProdutoAtual({});
      setOpenModalRemocao(false);
      setUpdate(!update);
    } catch (error) {
      enqueueSnackbar("Erro ao excluir lote", {
        variant: "error",
      });
    }
  }

  function BotaoEditarLote(params) {
    // setLoteSelecionado(params.row.lote);
    // setIdLote(params.row.id);

    setCodBarras(params.row.codigo_barra);
    setIdLote(params.row.id);

    // setData(params.row.validade);
    setEstoqueAtual({
      nome: params.row.estoque.nome,
      id: params.row.estoque.id,
    });

    setQuantidadeAtual(params.row.quantidade);

    setLocalSelecionado(params.row.estoque.unidade.id);
    setAtivaBotaoEditarLote(true);
    setOpen(true);
  }

  async function editarLote() {
    try {
      const quantidadeAtualConvertida = Number(quantidadeAtual);
      const retorno = await api.put(`/v2/almoxarifado/lote/editar/${idLote}`, {
        quantidade: quantidadeAtualConvertida,
      });

      // if (retorno?.data.atualizaLote?.message) {
      //   return enqueueSnackbar(
      //     "Código de barras já está cadastrado em outro produto",
      //     {
      //       variant: "error",
      //     }
      //   );
      // }
      setUpdate(!update);
      SalvaLog("Lote editado", "Almoxarifado", idLote);
      enqueueSnackbar("Lote editado com sucesso", {
        variant: "success",
      });
      setOpen(false);
    } catch (error) {
      enqueueSnackbar("Erro ao editar lote", {
        variant: "error",
      });
    }
  }

  const columns = [
    {
      field: "id",
      headerName: "id",
      headerAlign: "center",
    },
    {
      field: "nome",
      headerName: "Localização",
      flex: 1,
      headerAlign: "center",
      type: "string",
      valueGetter: (params) => params.row.estoque?.unidade?.nome,
    },
    {
      field: "estoque",
      headerName: "Estoque",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: true,
      type: "string",
      valueGetter: (params) => params.row?.estoque?.nome,
    },
    {
      field: "quantidade",
      headerName: "Quantidade",
      flex: 1,
      headerAlign: "center",
      align: "center",
      type: "number",
    },
    {
      field: "opcoes",
      headerName: "Opções",
      headerAlign: "center",
      width: 200,
      align: "center",
      type: "number",
      editable: false,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <div>
          {/* <EditIcon
            size="small"
            onClick={() => BotaoEditarLote(params)}
            style={{ color: "#000", cursor: "pointer", marginRight: "15px" }}
            fontSize="small"
          /> */}
          <DeleteIcon
            size="small"
            onClick={() => {
              setOpenModalRemocao(true);
              setProdutoAtual(params);
            }}
            style={{ color: "#000", cursor: "pointer", marginRight: "5px" }}
            fontSize="small"
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (edicaoProduto) {
      setQtdPacote(edicaoProduto.produto.quantidadeUnitaria);
      setNomeProduto(edicaoProduto.produto.nome);
      setValorProduto(edicaoProduto.produto.valor || 0);
      setCodBarras(edicaoProduto.produto.codigoProduto);
      setUnidadeSelecionada(edicaoProduto.produto.unidadeMedida);
      setCategoriaSelecionada(edicaoProduto.produto.categoriaId);
      setAtivaLote(true);
      setLoteSelecionado(edicaoProduto.lote);
      setAtivaTabela(true);
      setIdProdutoAtual(edicaoProduto.produto.id);
      pegaDadosLote(edicaoProduto.produto.id);
    }

    async function pegaDadosLote(id) {
      try {
        const resultLote = await api.get(
          `/v2/almoxarifado/lote?id_produto=${id}`
        );
        if (resultLote.data.lote === undefined) {
          enqueueSnackbar("Não existe lote cadastrado para este produto", {
            variant: "warning",
          });
        } else {
          setTabelaDados(resultLote.data.lote);
        }
      } catch (error) {
        enqueueSnackbar("Erro ao buscar lote", {
          variant: "error",
        });
      }
    }

    async function pegaDadosCategorias() {
      try {
        const {
          data: { data },
        } = await api.get("/v2/almoxarifado/categorias");

        setCategorias(data);
      } catch (error) {
        enqueueSnackbar("falha ao obter os dados", {
          variant: "error",
        });
      }
    }

    async function pegaTodasUnidades() {
      try {
        const resultUnidades = await api.get(
          "/v2/almoxarifado/estoque/unidades"
        );

        setLocal(resultUnidades.data.unidades);
      } catch (e) {
        enqueueSnackbar("Falha ao obter as unidades", { variant: "error" });
      }
    }

    pegaTodasUnidades();
    pegaDadosCategorias();
  }, [enqueueSnackbar, edicaoProduto, update]);

  function limpaCampos() {
    setData(null);
    setLoteSelecionado("");
    setLocalSelecionado("");
    setCodBarras("");
    setQuantidadeAtual(0);
    setEstoqueAtual({ nome: "", id: "" });
  }

  function handleCategoria(e) {
    setCategoriaSelecionada(e);
  }

  async function handleSubmitLote() {
    try {
      setLoadingSubmitLote(true);

      const quantidadeAtualConvertida = Number(quantidadeAtual);
      const retorno = await api.post("/v2/almoxarifado/lote", {
        id_produto: idProdutoAtual,
        id_estoque: estoqueAtual?.id,
        quantidade: quantidadeAtualConvertida,
      });

      await api.post("/v2/almoxarifado/movimentcaoProdutoEstoque/entrada", {
        id_produto_estoque: retorno?.data?.criaLote?.id,
        data: new Date(),
        tipo: "Entrada",
        origem_destino: localSelecionado,
        quantidade: quantidadeAtualConvertida,
      });

      // if (retorno?.data.criaLote?.message) {
      //   return enqueueSnackbar(
      //     "Código de barras já está cadastrado em outro produto",
      //     {
      //       variant: "error",
      //     }
      //   );
      // }
      SalvaLog("Lote cadastrado", "Almoxarifado", loteSelecionado);
      enqueueSnackbar("Lote cadastrado com sucesso", {
        variant: "success",
      });

      setAtivaTabela(true);
      limpaCampos();
      setOpen(false);
      setUpdate(!update);
    } catch (error) {
      enqueueSnackbar("falha ao salvar o lote", {
        variant: "error",
      });
    } finally {
      setLoadingSubmitLote(false);
    }
  }

  async function handleSubmitSimples() {
    try {
      await api.post("/v2/almoxarifado/produto", {
        nome: nomeProduto,
        unidadeMedida: unidadeSelecionada,
        quantidadeUnitaria: qtdPacote,
        categoriaId: categoriaSelecionada,
        ativo: true,
        valor: valorProduto,
        codigoProduto: codBarras,
      });
      setAtivaLote(true);
      setAtivaBotaoAdicionar(true);
      SalvaLog("Produto cadastrado nome", "Almoxarifado", nomeProduto);
      enqueueSnackbar("Produto cadastrado com sucesso", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Falha ao cadastrar o produto", {
        variant: "error",
      });
    }
  }

  async function handleSalvaAlteracoes() {
    try {
      await api.put(`/v2/almoxarifado/produto/${edicaoProduto.produto.id}`, {
        nome: nomeProduto,
        codigo_barra: codBarras,
        unidadeMedida: unidadeSelecionada,
        quantidadeUnitaria: qtdPacote,
        categoriaId: categoriaSelecionada,
        valor: valorProduto,
      });
      SalvaLog("Produto alterado", "Almoxarifado", edicaoProduto.produto.id);
      enqueueSnackbar("Produto alterado com sucesso", {
        variant: "success",
      });
      //history.push("/almoxarifado/produtos");
    } catch (e) {
      enqueueSnackbar("Falha ao alterar o produto", {
        variant: "error",
      });
    }
  }

  async function excluirProduto() {
    try {
      await api.put(
        `/v2/almoxarifado/produto/excluir/${edicaoProduto.produto.id}`
      );
      SalvaLog("Produto excluido", "Almoxarifado", edicaoProduto.produto.id);
      enqueueSnackbar("Produto excluido com sucesso", {
        variant: "success",
      });
      history.push("/almoxarifado/produtos");
    } catch (e) {
      enqueueSnackbar("Falha ao excluir o produto", {
        variant: "error",
      });
    }
  }

  const handleLocalUnidade = (e) => {
    setLocalSelecionado(e);
  };

  const corpoModal = (
    <Container
      maxWidth="md"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Paper elevation={2}>
        <Grid container style={{ padding: "1rem" }}>
          <Grid item xs={6} sm={6}>
            <h2>Inserir lote</h2>
          </Grid>
          <Grid id="botao-fechar" item xs={6} sm={6}>
            <Button type="button" onClick={() => setOpen(false)}>
              <CloseIcon fontSize="medium" style={{ color: "#003399" }} />
            </Button>
          </Grid>
          <Grid container item gap={2} xs={12}>
            <Grid container item gap={2} xs={12} justifyContent="space-between">
              <Grid item xs={12} sm={6}>
                <SelectLocalUnidades
                  label="Localização"
                  locais={local}
                  setValue={handleLocalUnidade}
                  value={localSelecionado}
                  ativo={semAcessoUnidade}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <AutocompleteEstoques
                  onChange={setEstoqueAtual}
                  value={estoqueAtual}
                  idUnidade={localSelecionado}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                variant="standard"
                onChange={(e) => setQuantidadeAtual(e.target.value)}
                value={quantidadeAtual}
                label="Quantidade"
              />
            </Grid>
          </Grid>
          <Grid className="input-text-modal" id="botao-cadastrar" item xs={12}>
            {ativaBotaoEditarLote ? (
              <Button
                onClick={() => editarLote()}
                type="button"
                variant="contained"
                style={{ color: "#fff", backgroundColor: "#003399" }}
              >
                Salvar
              </Button>
            ) : (
              <Button
                onClick={() => handleSubmitLote()}
                type="button"
                variant="contained"
                disabled={
                  !localSelecionado ||
                  estoqueAtual.id === "" ||
                  quantidadeAtual === 0 ||
                  loadingSubmitLote
                }
              >
                Cadastrar
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );

  const modalConfirm = (
    <Container maxWidth="xs" style={{ padding: "0", marginTop: "300px" }}>
      <Paper className="paper-cadastro" elevation={2}>
        <Grid
          style={{
            padding: "1rem",
            marginLeft: "auto",
          }}
          spacing={2}
          container
        >
          <Grid item xs={12} sm={12}>
            <h3>Tem certeza que deseja excluir o produto?</h3>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Button style={{ color: "red" }} onClick={() => excluirProduto()}>
              Sim
            </Button>
          </Grid>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            item
            xs={6}
            sm={6}
          >
            <Button onClick={() => setOpenConfirm(false)}>Não</Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );

  const handleClickDelete = () => {
    if (!checarPermissao("AL006")) {
      enqueueSnackbar("É necessário a permissão AL006", {
        variant: "warning",
      });
    } else {
      setOpenConfirm(true);
    }
  };

  const handleEditCodigoBarra = async (coluna) => {
    try {
      const retorno = await api.put(
        `/v2/almoxarifado/lote/editar/barras/${dadosEdicao.id}`,
        {
          codigoBarras: coluna?.value,
        }
      );

      if (retorno?.data.atualizaCodigoBarras?.message) {
        return enqueueSnackbar(
          "Código de barras já está cadastrado em outro produto",
          {
            variant: "error",
          }
        );
      }

      setUpdate(!update);
      enqueueSnackbar("Código de barras alterado com sucesso", {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar("Erro ao alterar o código de barras", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <ModalConfirmacao
        onClose={setOpenModalRemocao}
        open={openModalRemocao}
        modalText="Tem certeza que deseja remover este produto?"
        onClick={() => {
          excluirLote(produtoAtual);
        }}
      />
      <Header
        titulo="Cadastro de Produto"
        usuario={usuario}
        history={history}
      />
      <Container maxWidth="lg" style={{ padding: "20px" }}>
        <Button
          onClick={() => history.push("/almoxarifado/produtos")}
          style={{ color: "#003399" }}
        >
          <ArrowBackIcon />
        </Button>

        <Paper style={{ padding: "40px", marginTop: "10px" }} elevation={2}>
          <Grid spacing={4} container>
            <Grid item xs={12} sm={12}>
              <h2>Adicionar Item(s)</h2>
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Cod. Sankhya"
                variant="standard"
                size="small"
                fullWidth
                type="text"
                value={codBarras}
                onChange={(e) => setCodBarras(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Nome"
                variant="standard"
                size="small"
                fullWidth
                type="text"
                value={nomeProduto}
                onChange={(e) => setNomeProduto(e.target.value)}
              />
            </Grid>

            <Grid item xs={6} sm={3}>
              <FormControl
                defaultValue=" "
                size="small"
                variant="standard"
                fullWidth
              >
                <InputLabel id="select-label">Categoria</InputLabel>
                <Select
                  labelId="select-label"
                  id="select"
                  label="Ramal"
                  value={categoriaSelecionada}
                  onChange={(e) => handleCategoria(e.target.value)}
                >
                  {categorias.map((categ) => (
                    <MenuItem key={categ.id} value={categ.id}>
                      {categ.categoria}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={3}>
              <TextField
                label="Valor"
                variant="standard"
                size="small"
                fullWidth
                type="number"
                value={valorProduto}
                onChange={(e) => setValorProduto(e.target.value)}
              />
            </Grid>

            <Grid
              className="botao-add-lote"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              item
              xs={12}
              sm={12}
            >
              {edicaoProduto ? (
                <Button
                  style={{ color: "#003399" }}
                  onClick={() => handleSalvaAlteracoes()}
                >
                  Salvar alterações
                </Button>
              ) : (
                ativaBotaoAdicionar === false && (
                  <Button
                    onClick={() => handleSubmitSimples()}
                    disabled={
                      !nomeProduto ||
                      !unidadeSelecionada ||
                      !categoriaSelecionada
                    }
                  >
                    Adicionar
                  </Button>
                )
              )}
              {ativaLote === true && (
                <Button
                  onClick={() => {
                    setLocalSelecionado("");
                    setEstoqueAtual({ nome: "", id: "" });
                    setQuantidadeAtual(0);
                    setOpen(true);
                  }}
                  variant="outlined"
                  style={{ color: "#003399", borderColor: "#003399" }}
                >
                  Adicionar lote
                </Button>
              )}
              <Modal open={open} onClose={() => setOpen(false)}>
                {corpoModal}
              </Modal>
            </Grid>
            {ativaTabela === true && (
              <Grid item xs={12} sm={12}>
                <Box sx={{ height: "100%", width: "100%" }}>
                  <Tabela
                    rows={tabelaDados}
                    columns={columns}
                    autoHeight
                    onCellEditCommit={(coluna) => handleEditCodigoBarra(coluna)}
                    onCellEditStart={(dados) => setDadosEdicao(dados.row)}
                    initialState={{
                      columns: { columnVisibilityModel: { id: false } },
                    }}
                  />
                </Box>
              </Grid>
            )}
            {(ativaTabela === true || ativaLote === true) && (
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: edicaoProduto ? "space-between" : "end",
                }}
                item
                xs={12}
                sm={12}
              >
                {edicaoProduto && (
                  <Button
                    onClick={() => handleClickDelete()}
                    style={{ color: "red" }}
                  >
                    Excluir
                  </Button>
                )}
                <Modal open={openConfirm} onClose={() => setOpenConfirm(false)}>
                  {modalConfirm}
                </Modal>
                <Button
                  onClick={() => history.push("/almoxarifado/produtos")}
                  style={{ color: "#003399", borderColor: "#003399" }}
                  varaint="outlined"
                >
                  Salvar
                </Button>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Container>
    </>
  );
}
