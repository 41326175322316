import React, { useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Paper } from "@mui/material";

import { api } from "../../../../services/Api";

import filtroPesquisa from "../../../../utils/filtroPesquisa";
import DadosContext from "../DadosContext";
import Opcoes from "./opcoes";
import Tabela from "../../../../componentsv2/Tabela";

export default function TabelaProdutos() {
  const { pesquisado } = useContext(DadosContext);
  const { enqueueSnackbar } = useSnackbar();
  const [produtosObtidos, setProdutosObtidos] = useState([]);

  useEffect(() => {
    async function carregaProdutos() {
      try {
        const {
          data: { data },
        } = await api.get("/v2/almoxarifado/produtos");

        setProdutosObtidos(data);
      } catch (e) {
        enqueueSnackbar("Falha ao carregar os produtos", { variant: "error" });
      }
    }

    carregaProdutos();
  }, [enqueueSnackbar]);

  const coluna = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "codigoProduto",
      headerName: "Cód. Sankhya",
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "nome",
      headerName: "Nome",
      width: 300,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "categoria",
      headerName: "Categoria",
      width: 370,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row?.categoria?.categoria,
    },
    {
      field: "opcoes",
      headerName: "Opções",
      headerAlign: "center",
      align: "center",
      width: 180,
      type: "number",
      editable: false,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <div style={{ cursor: "pointer" }}>
          <Opcoes row={params.row} />
        </div>
      ),
    },
  ];

  const filtrada = filtroPesquisa(produtosObtidos, "nome", pesquisado);

  return (
    <Paper elevation={2}>
      <div style={{ height: 600, width: "100%" }}>
        <Tabela
          initialState={{ columns: { columnVisibilityModel: { id: false } } }}
          rows={filtrada}
          columns={coluna}
          pageSize={5}
          disableColumnMenu
        />
      </div>
    </Paper>
  );
}
