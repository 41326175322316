import React, { createContext, useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";

import { IntervaloDataContext } from "../../Context/IntevervaloDataContext";
import { ModalRecebimentoContext } from "./ModalRecebimentoContext";
import checarData from "../../../../utils/checarData";

import { api } from "../../../../services/Api";
import formatDateBD from "../../../../utils/formatarDataBD";
import { Context as PermissoesContext } from "../../../../Context/PermissoesContext";

const RecebimentoContext = createContext();

function RecebimentoContextProvider({ children }) {
  const { enqueueSnackbar } = useSnackbar();

  const { dataInicio, dataFim, competencia, setNotaFiscal, notaFiscal } =
    useContext(IntervaloDataContext);

  const { id_lote, id_retorno_lote, fechaModalOpcoesGerenciaLote, setLoading } =
    useContext(ModalRecebimentoContext);

  const [dadosTotalizadores, setDadosTotalizadores] = useState([]);
  const [valores, setValores] = useState([]);
  const [aba, setAba] = useState("Enviado");

  const [resultado, setResultado] = useState([]);
  const [dadosTabela, setDadosTabela] = useState([]);
  const [atualizaFiltro, setAtualizaFiltro] = useState(false);
  const [atualizaTabela, setAtualizaTabela] = useState(false);

  const [protocolo, setProtocolo] = useState("");
  const [plano, setPlano] = useState([{ id: 0, convenio: "Todos" }]);
  const [responsavelPlano, setResponsavelPlano] = useState([
    { id: 0, grupo: "Todos" },
  ]);
  const [lote, setLote] = useState("");

  const [motivos, setMotivos] = useState([]);
  const [retornoLote, setRetornoLote] = useState([]);
  const [retornoLoteAgrupado, setRetornoLoteAgrupado] = useState([]);
  const [atualizaRetornoLote, setAtualizaRetornoLote] = useState(false);

  const [atualizaGeradoSoftlab, setAtualizaGeradoSoftlab] = useState(false);

  const [listaConvenios, setListaConvenios] = useState([
    { id: 0, convenio: "" },
  ]);

  const [listaResponsaveisFiltrados, setListaResponsaveisFiltrados] = useState(
    []
  );
  const { checarPermissao } = useContext(PermissoesContext);
  const [tela, setTela] = useState("Principal");
  const [abaAtual, setAbaAtual] = useState("faturamento");

  // Preenche a tabela de recebimento (visão lote)
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (
          checarData(formatDateBD(dataInicio)) &&
          checarData(formatDateBD(dataFim))
        ) {
          const data_inicial = `${formatDateBD(dataInicio)} 00:00:00`;
          const data_final = `${formatDateBD(dataFim)} 23:59:59`;

          const { data: results } = await api.get(
            `/v2/recebimento/tabelaRecebimento`,
            {
              params: {
                data_inicial,
                data_final,
                competencia,
                nota_fiscal: notaFiscal,
              },
            }
          );
          if (abaAtual === "recebimento") {
            setResultado(results);
          }

          setDadosTotalizadores(results);
          setDadosTabela(results);
          setAtualizaFiltro((prevState) => !prevState);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        enqueueSnackbar(
          "Não foi possível encontrar pedidos desta data. Por favor, tente novamente.",
          { variant: "error" }
        );
      }
    })();
    // eslint-disable-next-line
  }, [enqueueSnackbar, atualizaTabela, abaAtual]);

  // Ajusta somatório sempre que os dados da tabela forem alterados
  useEffect(() => {
    (() => {
      const vetorTotal = [];

      let somaTotalGlosadoTodos = 0;

      let somaTotalEnviado = 0;
      let somaTotalRecuperado = 0;
      let somaTotalLiquido = 0;
      let somaTotalGlosado = 0;
      let somaTotalAcatado = 0;
      let somaTotalEmRecurso = 0;
      let somaTotalDiferencatabela = 0;
      let somaTotalRecurso = 0;
      let somaTotalValorRecebido = 0;

      dadosTotalizadores.forEach((element) => {
        somaTotalEnviado += element.valor_enviado;
        somaTotalRecuperado += element.valor_recuperado;
        somaTotalLiquido += element.valor_liquido;
        somaTotalGlosadoTodos +=
          element.valor_glosado +
          element.em_analise +
          element.por_recurso +
          element.valor_acatado +
          element.valor_diferenca_tabela;
        somaTotalGlosado += element.valor_glosado;
        somaTotalAcatado += element.valor_acatado;
        somaTotalEmRecurso += element.em_analise;
        somaTotalDiferencatabela += element.valor_diferenca_tabela;
        somaTotalRecuperado += element.recurso ? element.valor_recuperado : 0;
        somaTotalRecurso += element.por_recurso;

        somaTotalValorRecebido += element.valor_recebido;
      });

      vetorTotal.push(
        { titulo: "Enviado", valor: somaTotalEnviado },
        { titulo: "Glosas Totais", valor: somaTotalGlosadoTodos },
        { titulo: "Faturado / Liberado", valor: somaTotalRecuperado },
        { titulo: "Vlr Líquido", valor: somaTotalLiquido },
        { titulo: "Recebido em c/c", valor: somaTotalValorRecebido },

        { titulo: "Diferença de tabela", valor: somaTotalDiferencatabela },
        { titulo: "Glosado", valor: somaTotalGlosado },
        { titulo: "Glosa Acatada", valor: somaTotalAcatado },

        { titulo: "Em recurso", valor: somaTotalEmRecurso },
        { titulo: "Valor recebido por recurso", valor: somaTotalRecurso }
      );

      setValores(vetorTotal);
    })();
  }, [resultado, dadosTotalizadores]);

  // Preenche tabela de Gereciamento de lote (visão lote)
  useEffect(() => {
    (async () => {
      if (tela === "Lote" && id_lote.length > 0) {
        try {
          setLoading(true);
          const { data: results } = await api.get(
            `/v2/recebimento/tabelaRetornoLote/${id_lote}`
          );

          setRetornoLote(results);
          setLoading(false);
        } catch (e) {
          setLoading(false);
          enqueueSnackbar(
            "Não foi possível encontrar pedidos desta data. Por favor, tente novamente.",
            { variant: "error" }
          );
        }
      }
    })();
    // eslint-disable-next-line
  }, [enqueueSnackbar, tela, id_lote, atualizaRetornoLote]);

  useEffect(() => {
    (async () => {
      if (motivos.length < 1) {
        try {
          const { data: results } = await api.get(`/v2/faturamento/motivos`);

          const listaMotivo = results?.map((r) => ({
            id: r.id,
            descricao: r.descricao,
            codigo: r.codigo,
            codigoDescricao: `(${r.codigo}) ${r.descricao}`,
          }));

          setMotivos(listaMotivo);
        } catch (e) {
          enqueueSnackbar(
            "Não foi possível carregar lista de motivos. Por favor, tente novamente.",
            { variant: "error" }
          );
        }
      }
    })();
    // eslint-disable-next-line
  }, []);

  // Exclui o recebimento de um lote no modal de gerenciamento lote
  async function excluiNotaRetornoLote() {
    try {
      if (!checarPermissao("FAT011")) {
        enqueueSnackbar("Você não tem permissão para realizar esta ação", {
          variant: "error",
        });
        return;
      }
      setLoading(true);
      await api.delete(`/v2/recebimento/apagaRetornoLoteManual`, {
        params: { id_retorno_lote },
      });

      fechaModalOpcoesGerenciaLote();
      setAtualizaRetornoLote((prevState) => !prevState);
      setAtualizaTabela((prevState) => !prevState);

      setLoading(false);
      enqueueSnackbar("Operação realizada com sucesso", { variant: "success" });
    } catch (e) {
      setLoading(false);
      enqueueSnackbar("Falha durante a exclusão. Por favor, tente novamente.", {
        variant: "error",
      });
    }
  }

  const getConvenios = async () => {
    try {
      const results = await api.get("/v2/faturamento/convenios");
      const lista1 = [{ id: 0, convenio: "Todos" }];
      const lista2 = results.data;
      const listaConvenio = lista1.concat(lista2);

      setListaConvenios(listaConvenio);
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(dadosTabela);

  useEffect(() => {
    const uniqueObj = {};
    const arraySemRepetidos = listaConvenios?.filter((obj) => {
      if (!uniqueObj[obj.grupo]) {
        uniqueObj[obj.grupo] = true;
        return true;
      }
      return false;
    });

    const lista1 = [{ id: 0, grupo: "Todos" }];
    const lista2 = arraySemRepetidos;
    const listaConvenio = lista1.concat(lista2);

    setListaResponsaveisFiltrados(listaConvenio);
  }, [listaConvenios]);

  useEffect(() => {
    getConvenios();
  }, []);

  return (
    <RecebimentoContext.Provider
      value={{
        dadosTotalizadores,
        setDadosTotalizadores,
        valores,
        setValores,
        resultado,
        setResultado,
        dadosTabela,
        setDadosTabela,
        atualizaFiltro,
        setAtualizaFiltro,
        atualizaTabela,
        setAtualizaTabela,
        retornoLote,
        setRetornoLote,
        retornoLoteAgrupado,
        setRetornoLoteAgrupado,
        atualizaRetornoLote,
        setAtualizaRetornoLote,
        excluiNotaRetornoLote,
        aba,
        setAba,
        tela,
        setTela,
        protocolo,
        setProtocolo,
        plano,
        setPlano,
        lote,
        setLote,
        motivos,
        listaConvenios,
        atualizaGeradoSoftlab,
        setAtualizaGeradoSoftlab,
        responsavelPlano,
        setResponsavelPlano,
        listaResponsaveisFiltrados,
        abaAtual,
        setAbaAtual,
      }}
    >
      {children}
    </RecebimentoContext.Provider>
  );
}

export { RecebimentoContext, RecebimentoContextProvider };
