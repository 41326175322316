import React, { useContext, useEffect, useState } from "react";

import FormularioRecebimento from "../FormularioRecebimento";
import TabelaRecebimento from "../TabelaRecebimento";
import TelaGerenciaLote from "../TelaGerenciaLote";
import TelaAgrupaLotes from "../TelaAgrupaLotes";
import ModalOpcoesGerenciaLote from "../ModalOpcoesGerenciaLote";
import ModalOpcoes from "../ModalOpcoes";

import Formulario from "../../../Componentes/Formulario";

import { RecebimentoContext } from "../../Context/RecebimentoContext";
import ModalInformarFaturamento from "../../../FaturamentoTabela/ModalInformarFaturamento";

export default function TelasRecebimento() {
  const {
    tela,
    setAtualizaTabela,
    atualizaTabela,
    listaConvenios,
    setAbaAtual,
  } = useContext(RecebimentoContext);
  const [atualizaTabelaFaturamento, setAtualizaTabelaFaturamento] =
    useState(false);
  const [openModalInformarFaturamento, setOpenModalInformarFaturamento] =
    useState(false);

  useEffect(() => {
    setAbaAtual("recebimento");
    setAtualizaTabela(!atualizaTabela);
  }, [tela]);

  return (
    <div>
      <ModalInformarFaturamento
        onClose={setOpenModalInformarFaturamento}
        open={openModalInformarFaturamento}
        listaConvenios={listaConvenios}
      />

      {/* Formulário principal, comum entre as abas faturamento e recebimento */}
      {tela === "Principal" && (
        <>
          <Formulario
            recebimento
            setOpenModalInformarFaturamento={setOpenModalInformarFaturamento}
          />
          <FormularioRecebimento />
          <TabelaRecebimento />
        </>
      )}
      {/* Modal de gerenciamento de notas fiscais recebidas */}
      {tela === "Lote" && <TelaGerenciaLote />}

      {tela === "agrupaLotes" && <TelaAgrupaLotes />}

      {/* Modal das opçoes da tabela de recebimento */}
      <ModalOpcoes />

      {/* Modal das opçoes da tabela de recebimento de lotes */}
      <ModalOpcoesGerenciaLote />
    </div>
  );
}
