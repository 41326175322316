import React from "react";
import { useState } from "react";
import {
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  TextField,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Input } from "../../../../../componentsv2/Input";
import Button from "../../../../../componentsv2/Button";

import MascaraDeCompetencia from "../../../Componentes/MascaraDeCompetencia";

import MascaraDeData from "../../../Componentes/MascaraDeData";

import { useSnackbar } from "notistack";

import { api } from "../../../../../services/Api";

import converteDataUS from "../../../../../utils/converteDataUS";

import useUser from "../../../../../hooks/useUser";

const ModalConfirmaLote = ({
  open,
  handleClose,
  convenio,
  valorSoftlab,
  getTotalSoftlab,
  lote,
  children,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [mesCompetencia, setMesCompetencia] = useState("");
  const [anoCompetencia, setAnoCompetencia] = useState("");
  const [protocolo, setProtocolo] = useState("");
  const [previsao, setPrevisao] = useState(null);

  const usuario = useUser();

  const salvarLote = async () => {
    adicionarProtocolo();
    let previs = previsao ? converteDataUS(previsao) : null;
    try {
      await api.post("/v2/faturamento/adicionaLote", {
        data_envio_lote: new Date(),
        codigo: lote,
        plano: convenio,
        protocolo: protocolo,
        data_protocolo: new Date(),
        data_previsao: previs,
        valor: valorSoftlab,
        quantidade: 1,
        em_construcao: false,
        usuario_responsavel: usuario,
      });
      enqueueSnackbar("lote e protocolo adicionados", {
        variant: "success",
      });
      getTotalSoftlab();
      handleClose(false);
      limparDados();
    } catch (error) {
      console.log(error);
    }
  };

  const adicionarProtocolo = async () => {
    let previs = previsao ? converteDataUS(previsao) : null;
    try {
      await api.post(`/v2/faturamento/salvaProtocolo`, {
        protocolo: protocolo,
        previsao: previs,
        codigo: lote,
        plano: convenio,
        mes_competencia: mesCompetencia,
        ano_competencia: anoCompetencia,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const limparDados = () => {
    setProtocolo("");
    setMesCompetencia("");
    setAnoCompetencia("");
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle
        id="scroll-dialog-title"
        sx={{ justifyContent: "space-between" }}
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Salvar Lote</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => handleClose(false)}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          <Grid container spacing={2} direction="column">
            <Grid container item spacing={1}>
              <Grid item xs={3} md={3}>
                <TextField id="lote" label="Lote" value={lote} disabled />
              </Grid>

              <Grid item xs={5} md={3}>
                <Input
                  id="protocolo"
                  label="Protocolo"
                  value={protocolo}
                  onChange={(e) => setProtocolo(e.target.value)}
                />
              </Grid>

              <Grid item xs={5} md={3}>
                <TextField id="convenio" label="Convênio" value={convenio} />
              </Grid>

              <Grid item xs={5} md={3}>
                <TextField
                  id="competencia"
                  placeholder="mm/aaaa"
                  style={{
                    marginRight: "1rem",
                    minWidth: "6rem",
                    width: "40%",
                  }}
                  label="Competência"
                  defaultValue={
                    mesCompetencia !== ""
                      ? `${mesCompetencia}/${anoCompetencia}`
                      : ""
                  }
                  onBlur={(e) => {
                    const competencia = e.target.value;
                    const [mes, ano] = competencia.split("/");
                    setMesCompetencia(mes);
                    setAnoCompetencia(ano);
                  }}
                  InputProps={{
                    inputComponent: MascaraDeCompetencia,
                  }}
                />
              </Grid>

              <Grid container item spacing={1}>
                <Grid item xs={5} md={3}>
                  <TextField
                    id="valorSoftlab"
                    label="Valor Softlab"
                    value={valorSoftlab?.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  />
                </Grid>

                <Grid item xs={5} md={5}>
                  <TextField
                    id="previsao"
                    placeholder="dd/mm/aaaa"
                    style={{
                      marginRight: "1rem",
                      minWidth: "6rem",
                      width: "40%",
                    }}
                    label="Data de Previsão"
                    onBlur={(e) => setPrevisao(e.target.value)}
                    defaultValue={previsao || ""}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: MascaraDeData,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: "16px 24px" }}>
        <Grid container item justifyContent="space-between" spacing={2}>
          <Grid item>
            <Button
              color="gray"
              variant="outlined"
              onClick={() => {
                handleClose(false);
                limparDados();
              }}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="green"
              //   onClick={() => salvarLote()}
              onClick={() => salvarLote()}
            >
              Salvar Lote
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirmaLote;
